import React from "react"
import { Container } from "react-bootstrap"
import styles from "../../scss/components/Cattaneo/_Main.module.scss"
import Logo from "../../images/Cattaneo/logo-bud.svg"

export default function Main() {
  return (
    <div className={styles.container}>
      <img src={Logo} style={{height:"10vh", alignSelf:"center", marginTop:"2%"}}/>
      <div className={styles.textsContainer}>
        <h1 className={styles.firstText}>
          BUD te invita a disfrutar la mejor experiencia con Cattaneo
        </h1>
        <h2 className={styles.secondText}>
          Completá con tus datos y participá por los mejores premios
        </h2>
      </div>
    </div>
  )
}
